import HeroSection from "../components/HeroSection";
import dynamic from "next/dynamic";

import getConfig from "next/config";

import { ContractData } from "../types/ContractData";
import { getContractData } from "../helpers/getContractData";

const { publicRuntimeConfig } = getConfig();

const Mint = dynamic(() => import("../components/Mint"));
const Features = dynamic(() => import("../components/Features"));
const FAQ = dynamic(() => import("../components/Faq"));
const Roadmap = dynamic(() => import("../components/Roadmap"));
const Blog = dynamic(() => import("../components/Blog"));
const Token = dynamic(() => import("../components/Token"));
// const Team = dynamic(() => import("../components/Team"));
const FeaturedIn = dynamic(() => import("../components/FeaturedIn"));

export default function Home(props: { contractData: ContractData }) {
  return (
    <>
      <main className="bg-maxima-background">
        <HeroSection />
        {publicRuntimeConfig.feature.mint && (
          <Mint contractData={props.contractData} />
        )}
        <Features />
        <Token />
        <Roadmap />
        {/* <Team /> */}
        <Blog />
        <FeaturedIn />
        <FAQ />
      </main>
    </>
  );
}

export async function getStaticProps() {
  if (publicRuntimeConfig.campaign.nft.useMockContractData) {
    const contractData = publicRuntimeConfig.campaign.nft.mockContractData;

    return {
      props: {
        contractData,
      },
    };
  }

  const contractData = await getContractData();

  return {
    props: {
      contractData,
    },
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 10 seconds
    revalidate: 10, // In seconds
  };
}
