/* eslint-disable @next/next/no-html-link-for-pages */
import React from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import Image from "next/image";
import marketplace from "./assets/images/marketplace.jpg";
import { DialogContext, DialogName } from "../context/DialogContext";

export default function HeroSection() {
  const { setCurrentDialog } = React.useContext(DialogContext);

  return (
    <>
      <section className="min-h-screen anchor-topBanner">
        <main className="flex items-center justify-center">
          <div>
            <div
              className="flex mx-auto max-w-7xl lg:px-10"
              style={{ minHeight: "calc(100vh - 98px - 48px)" }}
            >
              <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                <div className="max-w-md px-4 mx-auto sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center animate-fade-in-down">
                  <div>
                    <h1 className="mt-4 text-6xl font-extrabold tracking-tight text-center text-white lg:text-left sm:mt-5 lg:mt-6 xl:text-8xl">
                      <span className="block">World&apos;s First</span>
                      <span className="block py-2 font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-white to-maxima-secondary-400">
                        Racehorse Exchange
                      </span>
                    </h1>
                    <p className="mt-4 text-xl antialiased text-center text-gray-300 lg:text-left sm:mt-8 lg:text-lg xl:text-lg">
                      The world&apos;s first and largest digital marketplace for
                      Racehorse non-fungible tokens (NFTs) by ERC-721 &
                      ERC-1155. All racehorses are backed by their physical
                      counterparts.
                    </p>
                    <div className="mt-10">
                      <form
                        action="#"
                        className="sm:max-w-xl sm:mx-auto lg:mx-0"
                      >
                        <div className="flex flex-col items-center space-y-12 md:space-y-0 md:flex-row md:space-x-4 lg:justify-start">
                          {/* <div>
                            <a
                              href="https://whitepaper.maxima.horse/"
                              target="_blank"
                              rel="noreferrer"
                              className="p-4 font-medium text-white rounded-md shadow bg-maxima-secondary-400 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900"
                            >
                              Read White Paper
                            </a>
                          </div> */}
                          <div>
                            <button
                              onClick={() => {
                                window.open(
                                  "https://discord.gg/maxima",
                                  "_blank"
                                );
                              }}
                              className="p-3 font-medium text-yellow-500 bg-transparent border border-yellow-500 rounded-md disabled hover:border-maxima-secondary-400 hover:text-maxima-secondary-400"
                            >
                              Join Discord
                            </button>
                          </div>
                        </div>
                      </form>
                      {/* <p className="mt-8 text-sm text-center text-gray-500 lg:text-left">
                        * $Max Token is currently at Private Sale Stage. Contact
                        us to know more.{" "}
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap items-center my-12 lg:relative animate-fade-in-down">
                  <div className="max-w-md px-4 mx-auto sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                    <Image
                      src={marketplace}
                      alt="marketplace"
                      placeholder="blur"
                      priority
                    />
                    <div className="pt-4 text-center ">
                      <button
                        onClick={() => setCurrentDialog(DialogName.Contact)}
                        className="inline-flex items-center p-1 pr-2 text-white bg-black border rounded-full border-maxima-secondary-400 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
                      >
                        <span className="p-2 text-xs font-semibold leading-5 tracking-wide text-white uppercase rounded-full bg-maxima-secondary-400">
                          MARKETPLACE BETA
                        </span>
                        <span className="ml-4 text-sm">
                          Request Early Access
                        </span>
                        <ChevronRightIcon
                          className="w-5 h-5 ml-2 text-gray-500"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>
    </>
  );
}
